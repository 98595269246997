<template>
    <div v-if="user">
      <router-view></router-view>
    </div>
</template>

<script>
import {Auth, Hub} from "aws-amplify"

export default {
  name: "App",
  created() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log(event)
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          this.getUser().then(userData => this.user=userData);
          break;
        case 'signOut':
          this.user=null;
          Auth.federatedSignIn({provider:"auth.biomerieux.com"})
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });
    this.getUser().then(userData => {
      if(!userData) {
        return Auth.federatedSignIn({provider:"auth.biomerieux.com"})
      }
      this.user=userData;
    })
  },
  data() {
    return {
      user: undefined
    };
  },
  methods: {
    getUser:function() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
    }
  },
  beforeDestroy() {
    Hub.remove("auth")
  },
};
</script>