import VueRouter from 'vue-router'

import VideoPlayer from './components/VideoPlayer'
import Result from './components/Result'


// import SignIn from './components/SignIn'

const routes = [
  { path: '/', component: VideoPlayer },
  { path: '/result', component: Result },
  { path: '*',  redirect: "/"}
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router