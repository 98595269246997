import Vue from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify'
import '@aws-amplify/ui-vue';
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './router'


let AWS_USERPOOL_WEB_CLIENT_ID, AWS_COGNITO_SIGNIN_URL, AWS_COGNITO_SIGNOUT_URL

const hostname = window.location.hostname

if (hostname.includes('clinopsfullpotential.biomerieux.com')) {
  AWS_USERPOOL_WEB_CLIENT_ID = '3jk1v1ekdiv52a0i27t92g2vgi'
  AWS_COGNITO_SIGNIN_URL = 'https://clinopsfullpotential.biomerieux.com'
  AWS_COGNITO_SIGNOUT_URL = 'https://clinopsfullpotential.biomerieux.com'
} else {
  AWS_USERPOOL_WEB_CLIENT_ID = '6u6qg92n9ptsibl4kmi0rmna1m'
  AWS_COGNITO_SIGNIN_URL = 'https://clinopsfullpotential-dev.biomerieux.com'
  AWS_COGNITO_SIGNOUT_URL = 'https://clinopsfullpotential-dev.biomerieux.com'
}
  


Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_J3wMokpDu',
    userPoolWebClientId: AWS_USERPOOL_WEB_CLIENT_ID,
    oauth: {
        domain: 'ivs-poc.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: AWS_COGNITO_SIGNIN_URL,
        redirectSignOut: AWS_COGNITO_SIGNOUT_URL,
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Amplify)

new Vue({
  vuetify,
  render: h => h(App),
  router
}).$mount('#app')