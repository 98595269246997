<template>
  <amplify-authenticator>
  <v-app>
    <v-app-bar
        absolute
        color="white"
        elevate-on-scroll
        prominent
      >
        <v-img
          class="mx-2 clinops-logo"
          src="./../assets/clinops-logo.png"
          max-height="120"
          max-width="400"
        ></v-img>
  
        <v-spacer></v-spacer>
        
        <v-img
          class="mx-2"
          src="./../assets/bmx-logo.png"
          max-height="180"
          max-width="180"
        ></v-img>
      </v-app-bar>
    <v-main style="margin-top: 120px">
      <v-container class="main-bg" fluid padless>
        <div class="container">
          <v-card
            class="mx-auto"
            max-width="900"
            outlined
            style="margin-top: 30px"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-row v-for="item in results" :key="item.id">
                  <v-col>
                    <h4>{{ item.title }}</h4>
                    <small>{{ item.description }}</small>
                    <v-progress-linear
                      readonly
                      :value="item.count"
                      height="25"
                    >
                      <strong>{{ Math.ceil(item.count * 100 / totalVotes) }}% ({{ item.count }})</strong>
                    </v-progress-linear>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </v-container>
    </v-main>

    <v-footer
      dark
      padless
    >
      <v-card
        class="flex"
        flat
        tile
        color="#00427F"
      >
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>bioMérieux</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
  </amplify-authenticator>
</template>

<script>
import axios from 'axios'

export default {
  name: "Result",
  props: {
    player: null,
  },
  data() {
    return {
      options: [],
      votesDB: [],
      results: [],
      totalVotes: 0
    }
  },
  async created() {
    this.votesDB = await this.getResults()
    this.options = await this.setOptions()
    this.buildResults()
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    apiStage() {
      if (window.location.hostname.includes('clinopsfullpotential.biomerieux.com')) {
        return "Prod"
      } else {
        return "Stage"
      }
    }
  },
  methods: {
    async setOptions() {
      const response = await axios.get(`https://lfstxiipue.execute-api.eu-west-1.amazonaws.com/${this.apiStage}/options`)
      return response.data;
    },
    async getResults() {
      const response = await axios.get(`https://lfstxiipue.execute-api.eu-west-1.amazonaws.com/${this.apiStage}/votes`)
      return response.data;
    },
    buildResults() {
      let that = this
      const arrayResults = []
      that.options.forEach(option => {
        const count = that.votesDB.filter((obj) => obj.vote === option.id).length;
        that.totalVotes += count

        const result = {
          id: option.id,
          title: option.title,
          description: option.description,
          count: count
        }
        arrayResults.push(result)
      });

      arrayResults.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))

      this.results = arrayResults
    }
  },
};
</script>

<style scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
}
ul[class],
ol[class] {
  padding: 0;
}
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
figure,
blockquote,
dl,
dd {
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
ul[class],
ol[class] {
  list-style: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img {
  max-width: 100%;
  display: block;
}
article > * + * {
  margin-top: 1em;
}
input,
button,
textarea,
select {
  font: inherit;
}
body {
  overflow: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", sans-serif;
  user-select: none;
}
#app {
  background: #00427F;
  height: 100%;
}
.main-bg {
  background-image: url(./../assets/main-bg.png);
  background-position: center center;
  padding: 0 !important;
}
</style>
